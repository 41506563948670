<template>
  <SearchForm ref="searchFormRef" :formModel="searchController.formModel" @search="search" :searchLoading="tableController.loading">
    <template #form>
      <el-form-item prop="dateType">
        <el-select placeholder="日期类型" v-model="searchController.formModel.dateType">
          <el-option label="结算时间" value="SETTLE"></el-option>
          <el-option label="审核时间" value="APPR"></el-option>
          <el-option label="到账时间" value="ACCOUNT"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="dateRange">
        <el-date-picker
          v-model="searchController.formModel.dateRange"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
          :clearable="false"
          style="height: 32px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="status">
        <FetchSelect
          placeholder="订单状态"
          v-model="searchController.formModel.status"
          api="/web/sys/code/getSysCodeByParam"
          :postData="{ codeParam: 'DOCTOR_SETTLE_STATUS' }"
          textKey="codeValue"
          valueKey="codeKey"
        />
      </el-form-item>
    </template>

    <template #controll>
      <el-button type="primary" @click="exportStream">导出</el-button>
      <el-button type="primary" @click="exportSummary">导出汇总</el-button>
      <el-button type="primary" @click="exportDetail">导出明细</el-button>
      <el-button type="primary" @click="payInBatch">批量付款</el-button>
    </template>
  </SearchForm>
  <ProTable
    :columns="tableController.columns"
    :tableData="tableController.tableData"
    :loading="tableController.loading"
    :total="tableController.total"
    :pageState="tableController.pageState"
    @pageChange="tableController.onPageChange"
    :isSelection="true"
    :selectedRows="tableController.selectedRows"
    @select="tableController.onSelect"
    :selectable="(row) => row.status == '0'"
  />
  <!-- 模态 -->
  <DrawerModal ref="drawerModalRef" :size="650">
    <template v-slot:default="{ slotProps: slotProps }">
      <DetailTable :slotProps="slotProps" />
    </template>
  </DrawerModal>
  <ApprModal ref="ApprModalRef" @success="tableController.refresh" />
</template>

<script>
import { ref } from 'vue'
import SearchForm from '@/components/SearchForm'
import ProTable from '@/components/ProTable/index.vue'
import FetchSelect from '@/components/FetchSelect'
import CustomLink from '@/components/customStyle/CustomLink'
import DrawerModal from '@/components/modal/DrawerModal/index.vue'
import ApprModal from './ApprModal.vue'
import useExport from '@/hooks/useExport'
import DetailTable from './DetailTable.vue'
import useSearchFormCtrller from '@/hooks/useSearchFormCtrller'
import useTableController from '@/hooks/useTableController'
import useRequestData from '@/hooks/useRequestData'
import request from '@/utils/request'
import dayjs from 'dayjs'

export default {
  setup() {
    const drawerModalRef = ref(null)
    const ApprModalRef = ref(null)
    // searchForm
    const searchController = useSearchFormCtrller({
      form: {
        dateRange: [dayjs().subtract(30, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        status: '',
        dateType: 'SETTLE',
      },
    })
    //汇总导出
    const { setExportPostData, exportStream } = useExport('/web/system/doctorsettle/exportDoctorSettleList')
    //汇总导出
    const { setExportPostData: setSummaryExportPostData, exportStream: summaryExportStream } = useExport('/web/system/doctorsettle/exportDoctorSettleOrderSummaryList')
    //汇总详情
    const { setExportPostData: setDetailExportPostData, exportStream: detailExportStream } = useExport('/web/system/doctorsettle/exportDoctorSettleOrderList')

    // table
    const columns = [
      { label: '类型', prop: 'settleTypeName', width: 110 },
      { label: '结算单号', prop: 'settleNo', width: 110 },
      { label: '结算时间', prop: 'settleDateStr', width: 200 },
      { label: '到账时间', prop: 'accountDateStr', width: 150 },
      { label: '医生数量', prop: 'totalDoctor', width: 150 },
      { label: '订单总额（元）', prop: 'totalSettleFeeStr', width: 150 },
      { label: '结算状态', prop: 'statusName', width: 130 },
      {
        label: '操作',
        prop: 'actions',
        width: 150,
        fixed: 'right',
        render: (_, record) => {
          return (
            <div>
              {record.status === '0' && (
                <CustomLink
                  onClick={() => {
                    console.log(ApprModalRef)
                    ApprModalRef.value?.open(record)
                  }}
                >
                  审核
                </CustomLink>
              )}
              <CustomLink
                onClick={() => {
                  drawerModalRef.value.open(record)
                }}
              >
                详情
              </CustomLink>
              <CustomLink
                onClick={() => {
                  exportDetail(record)
                }}
              >
                导出明细
              </CustomLink>
              <CustomLink
                onClick={() => {
                  exportSummary(record)
                }}
              >
                导出汇总
              </CustomLink>
            </div>
          )
        },
      },
    ]

    const tableController = useTableController({
      columns,
      ajax: ({ page, pageSize }) => {
        const { dateRange, ...values } = searchController.formModel
        const postData = {
          page,
          rows: pageSize,
          startDate: dateRange?.[0],
          endDate: dateRange?.[1],
          ...values,
        }
        setExportPostData(postData)
        return request({
          url: '/web/system/doctorsettle/getDoctorSettleList',
          data: postData,
        }).then((data) => {
          return {
            totalNum: data.rowTop,
            list: data.data,
          }
        })
      },
    })

    function search() {
      tableController.search()
    }
    function refresh() {
      tableController.refresh()
    }
    const exportDetail = (record = null) => {
      let postData = {}
      if (record) {
        postData.settleNo = record.settleNo
      } else {
        const { dateRange, ...values } = searchController.formModel
        postData = {
          startDate: dateRange?.[0],
          endDate: dateRange?.[1],
          ...values,
        }
      }
      setDetailExportPostData(postData)
      detailExportStream()
    }
    const exportSummary = (record = null) => {
      let postData = {}
      if (record) {
        console.log(record)
        postData.settleNo = record.settleNo
      } else {
        const { dateRange, ...values } = searchController.formModel
        postData = {
          startDate: dateRange?.[0],
          endDate: dateRange?.[1],
          ...values,
        }
      }
      setSummaryExportPostData(postData)
      summaryExportStream()
    }

    const payInBatch = () => {
      let settleNoStr = tableController.selectedRows.map((item) => item.settleNo).join(',')
      ApprModalRef.value?.open({
        settleNo: settleNoStr,
      })
    }

    return {
      drawerModalRef,
      searchController,
      tableController,
      ApprModalRef,
      search,
      refresh,
      payInBatch,
      exportStream,
      exportDetail,
      exportSummary,
    }
  },
  components: { SearchForm, ProTable, FetchSelect, CustomLink, DetailTable, DrawerModal, ApprModal },
}
</script>

<style lang="less"></style>
<style lang="less" scoped>
@import url('~@/common/styles.less');
.statistics_wrap {
  .flexCenter;
  background-color: #fff;
  margin: 0 0 15px;
  padding: 15px 7.5px;
  .statistics_item {
    .flexColumn;
    flex: 1 0 0;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    margin: 0 7.5px;
    padding: 7px 0;
    .statistics_count {
      font-size: 17px;
      font-weight: bolder;
      margin-top: 3px;
      color: red;
    }
  }
}
</style>
