<template>
  <SearchForm ref="searchFormRef" :formModel="searchController.form" @search="tableController.search" :searchLoading="tableController.loading">
    <template #form>
      <el-form-item prop="doctorCode">
        <FetchSelect
          v-model="searchController.formModel.doctorCode"
          :postData="{ page: 1, rows: 100000 }"
          api="/web/system/doctorncp/getDoctorList"
          valueKey="doctorCode"
          textKey="doctorName"
        />
      </el-form-item>
    </template>

    <template #controll>
      <el-button type="primary" @click="exportStream">导出</el-button>
    </template>
  </SearchForm>
  <ProTable
    :tableId="tableController.tableId"
    :columns="tableController.columns"
    :tableData="tableController.tableData"
    :loading="tableController.loading"
    :total="tableController.total"
    :pageState="tableController.pageState"
    @pageChange="tableController.onPageChange"
  />
  <!-- 模态 -->
  <DrawerModal ref="drawerModalRef" :size="880">
    <template v-slot:default="{ slotProps: slotProps }">
      <DoctorSettleDetailTable :slotProps="slotProps" />
    </template>
  </DrawerModal>
</template>
<script>
import { toRefs, ref } from 'vue'
import SearchForm from '@/components/SearchForm/index.vue'
import ProTable from '@/components/ProTable'
import useExport from '@/hooks/useExport'
import FetchSelect from '@/components/FetchSelect/index.vue'
import useSearchFormCtrller from '@/hooks/useSearchFormCtrller'
import useTableController from '@/hooks/useTableController'
import DrawerModal from '@/components/modal/DrawerModal/index.vue'
import DoctorSettleDetailTable from './DoctorSettleDetailTable.vue'
import CustomLink from '@/components/customStyle/CustomLink'
import request from '@/utils/request'
export default {
  props: ['slotProps'],
  setup(props) {
    const { slotProps } = toRefs(props)
    const searchFormRef = ref(null)
    const drawerModalRef = ref(null)
    const searchController = useSearchFormCtrller({
      form: {
        doctorCode: '',
      },
    })
    //汇总导出
    const { setExportPostData, exportStream } = useExport('/web/system/doctorsettle/exportDoctorSettleOrderSummaryList')

    // table
    const columns = [
      { label: '结算时间', prop: 'settleDateStr', width: 110 },
      { label: '医生名称', prop: 'doctorName', width: 135 },
      { label: '订单总数', prop: 'orderCn', width: 90 },
      { label: '结算金额（元）', prop: 'doctorFeeStr', width: 120 },
      {
        label: '操作',
        prop: 'settings',
        width: 130,
        render: (_, record) => {
          return (
            <div>
              <CustomLink
                onClick={() => {
                  drawerModalRef.value.open({
                    ...record,
                    settleNo: slotProps.value?.settleNo,
                  })
                }}
              >
                详情
              </CustomLink>
            </div>
          )
        },
      },
    ]
    const tableController = useTableController({
      tableId: 'feeAccountMng_DetailTable_table',
      columns,
      ajax: ({ page, pageSize }) => {
        const { ...values } = searchController.formModel
        const postData = {
          page,
          rows: pageSize,
          settleNo: props.slotProps?.settleNo,
          ...values,
        }
        setExportPostData(postData)
        return request({
          url: '/web/system/doctorsettle/getDoctorSettleOrderSummaryListPaging',
          data: postData,
        }).then((data) => ({ list: data.data ?? [], totalNum: data.rowTop ?? 0 }))
      },
      defaultPageSize: 50,
      dependence: slotProps,
      getIsReady: (slotPropsValue) => slotPropsValue?.settleNo,
    })
    return { tableController, searchController, searchFormRef, drawerModalRef, exportStream }
  },
  components: { ProTable, SearchForm, DoctorSettleDetailTable, DrawerModal, CustomLink, FetchSelect },
}
</script>
