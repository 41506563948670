<template>
  <el-button type="primary" style="margin-left: 14px" @click="exportStream">导出</el-button>
  <ProTable
    :tableId="tableController.tableId"
    :columns="tableController.columns"
    :tableData="tableController.tableData"
    :loading="tableController.loading"
    :total="tableController.total"
    :pageState="tableController.pageState"
    @pageChange="tableController.onPageChange"
  />
</template>
<script>
import { toRefs, ref } from 'vue'
import ProTable from '@/components/ProTable'
import useExport from '@/hooks/useExport'
import useTableController from '@/hooks/useTableController'
import request from '@/utils/request'
export default {
  props: ['slotProps'],
  setup(props) {
    const { slotProps } = toRefs(props)
    // table
    const columns = [
      { label: '下单时间', prop: 'orderDateStr', width: 110 },
      { label: '订单编号', prop: 'orderNo', width: 135 },
      { label: '问诊类型', prop: 'orderTypeName', width: 90 },
      { label: '医生姓名', prop: 'doctorName', width: 120 },
      { label: '患者姓名', prop: 'patientName', width: 120 },
      { label: '金额（元）', prop: 'doctorFeeStr', width: 120 },
      { label: '结算金额（元）', prop: 'settleFeeStr', width: 120 },
    ]

    //汇总详情
    const { setExportPostData, exportStream } = useExport('/web/system/doctorsettle/exportDoctorSettleOrderList')

    const tableController = useTableController({
      tableId: 'feeAccountMng_DetailTable_table',
      columns,
      ajax: ({ page, pageSize }) => {
        console.log(props.slotProps)
        const postData = {
          page,
          rows: pageSize,
          settleNo: props.slotProps?.settleNo,
          doctorCode: props.slotProps?.doctorCode,
        }
        setExportPostData(postData)
        return request({
          url: '/web/system/doctorsettle/getDoctorSettleOrderListPaging',
          data: postData,
        }).then((data) => ({ list: data.data ?? [], totalNum: data.rowTop ?? 0 }))
      },
      defaultPageSize: 50,
      dependence: slotProps,
      getIsReady: (slotPropsValue) => slotPropsValue?.doctorCode,
    })

    const search = () => {
      tableController.search()
    }
    return { tableController, search, exportStream }
  },
  components: { ProTable },
}
</script>
